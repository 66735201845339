import { useEffect, useRef, useState } from "react";
import Center from "./components/Center";
import Left from "./components/Left";
import Right from "./components/Right";

function App() {
    const [state, setState] = useState({
        selectedColorSvg: 0,
        colorList: ["black", "darkblue", "maroon"],
        images: new Array(4).fill(null),
        text: "Hello World",
        fontFamily: "Macondo Swash Caps",
        textSize: 74,
        textColor: "#DE3163",
        textStripeColor: "darkblue",
        textTrimSize: 0,
        lefttext: "",
        leftfontFamily: "Macondo Swash Caps",
        lefttextSize: 60,
        lefttextColor: "#DE3163",
        lefttextStripeColor: "darkblue",
        lefttextTrimSize: 0,
        righttext: "",
        rightfontFamily: "Macondo Swash Caps",
        righttextSize: 60,
        righttextColor: "#DE3163",
        righttextStripeColor: "darkblue",
        righttextTrimSize: 0,
        imageStripeColor: "darkblue",
        imageTrimSize: 0,
        textx: 0,
        texty: 0,
        lefttextx: 0,
        lefttexty: 0,
        righttextx: 0,
        righttexty: 0,
    });

    const [canvas, setCanvas] = useState(null);
    const [canvasScale, setCanvasScale] = useState(1);
    const exportButtonClickHandler = (e) => {
        let image = document.getElementById("canvas").toDataURL("image/png");
        downloadImage(image, `my-builder-gen${new Date().getTime()}.png`);
    };

    const forRunOnce = useRef();

    function downloadImage(data, filename) {
        var a = document.createElement("a");
        a.href = data;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
    }

    useEffect(() => {
        if (forRunOnce.current) {
            return;
        }
        forRunOnce.current = true;
        loadFonts();
        return () => {};
    }, []);

    function loadFonts() {
        let fontsArray = [
            new FontFace("College", "url(/fonts/college.woff)"),
            new FontFace("Impact", "url(/fonts/impact.woff)"),
            new FontFace("Sweet Sensation", "url(/fonts/Sweet-Sensations.woff2)"),
            new FontFace("Pieces", "url(/fonts/PiecesNFI.woff2)"),
        ];
        fontsArray.forEach((font) => {
            font.load().then(() => {
                document.fonts.add(font);
            });
        });
    }
    var toAuthorizeArray;
    try {
        toAuthorizeArray = JSON.parse(atob(document.querySelector("meta[name=authorize]").content));
    } catch (err) {
        toAuthorizeArray = [];
    }

    return (
        <div className="main-container">
            <Left canvasScale={canvasScale} setCanvasScale={setCanvasScale} setState={setState} />
            <Center
                colorList={state["colorList"]}
                selectedColorSvg={state["selectedColorSvg"]}
                images={state["images"]}
                text={state["text"]}
                fontFamily={state["fontFamily"]}
                textSize={state["textSize"]}
                textColor={state["textColor"]}
                textStripeColor={state["textStripeColor"]}
                imageStripeColor={state["imageStripeColor"]}
                textTrimSize={state["textTrimSize"]}
                imageTrimSize={state["imageTrimSize"]}
                lefttext={state["lefttext"]}
                leftfontFamily={state["leftfontFamily"]}
                lefttextSize={state["lefttextSize"]}
                lefttextColor={state["lefttextColor"]}
                lefttextStripeColor={state["lefttextStripeColor"]}
                lefttextTrimSize={state["lefttextTrimSize"]}
                righttext={state["righttext"]}
                rightfontFamily={state["rightfontFamily"]}
                righttextSize={state["righttextSize"]}
                righttextColor={state["righttextColor"]}
                righttextStripeColor={state["righttextStripeColor"]}
                righttextTrimSize={state["righttextTrimSize"]}
                textx={state["textx"]}
                lefttextx={state["lefttextx"]}
                righttextx={state["righttextx"]}
                canvas={canvas}
                setCanvas={setCanvas}
                canvasScale={canvasScale}
            />
            <Right
                state={state}
                setState={setState}
                exportButtonClickHandler={exportButtonClickHandler}
                toAuthorizeArray={toAuthorizeArray}
            />
        </div>
    );
}

export default App;
