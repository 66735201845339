import React from "react";

function ColorPicker({ id, value, onColorChange, maxWidth = "180px" }) {
    const colorList = [
        { name: "Black", color: "black" },
        { name: "Dark Blue", color: "darkblue" },
        { name: "Maroon", color: "maroon" },
        { name: "Silver", color: "silver" },
        { name: "Gold", color: "#d4af37" },
        { name: "Royal Blue", color: "royalblue" },
        { name: "Lilac", color: "#C8A2C8" },
        { name: "Sky Blue", color: "skyblue" },
        { name: "Turquoise", color: "turquoise" },
        { name: "White", color: "white" },
        { name: "Yellow", color: "yellow" },
        { name: "Fluorescent Orange", color: "#FF7B19" },
        { name: "Fluorescent Yellow", color: "#ccff00" },
        { name: "Fluorescent Pink", color: "#FF1493" },
        { name: "Fluorescent Green", color: "#79FE0C" },
        { name: "Fluorescent Red", color: "#FF3131" },
        { name: "Green", color: "green" },
        { name: "Clear", color: "#dcf0ef00" },
        { name: "Pink", color: "pink" },
        { name: "Racing Green", color: "#004225" },
        { name: "Brown", color: "brown" },
        { name: "Apple", color: "#66b447" },
        { name: "Lavender", color: "lavender" },
        { name: "Cerise", color: "#DE3163" },
        { name: "Sandstone", color: "#BFA674" },
        { name: "Cherry Red", color: "#D2042D" },
        { name: "Amethyst", color: "#9966cc" },
        { name: "Teal", color: "teal" },
        { name: "Aussie Yellow", color: "#f5a600" },
        { name: "Mint Green", color: "#98ff98" },
        { name: "Shrimp Pink", color: "#E5B5A1" },
        { name: "Metallic Green", color: "#296e01" },
        { name: "Corn Flower Antibacterial", color: "#9aceeb" },
        { name: "Leaf Green Bio-degradeable", color: "#2D5A27" },
        { name: "Violet", color: "violet" },
        { name: "Orange", color: "orange" },
    ];
    return (
        //select options
        <select style={{ maxWidth: maxWidth, width: "100%" }} value={value} id={id} onChange={onColorChange}>
            {colorList.map((color) => (
                <option key={color.name} value={color.color}>
                    {color.name}
                </option>
            ))}
        </select>
    );
}

export default ColorPicker;

/* 
    {
        { color: "#9e9c98", name: "SILVER" },
        { color: "#ff8000", name: "FLUORESCENT ORANGE" },
        { color: "#eb9bd0", name: "BABY PINK" },
        { color: "#4bbdd1", name: "TEAL" },
        { color: "#b85be3", name: "PURPLE" },
        { color: "#faf60c", name: "FLUORESCENT YELLOW" },
        { color: "#d1b40d", name: "MUSTARD YELLOW" },
        { color: "#22b5f0", name: "SKY BLUE" },
        { color: "#1c42eb", name: "ROYAL BLUE" },
        { color: "#fffcfc", name: "WHITE" },
        { color: "#19e3d5", name: "TURQUOISE" },
        { color: "#663c25", name: "MAROON" },
        { color: "#070940", name: "DARK BLUE" },
        { color: "#5dd14d", name: "GREEN" },
        { color: "#0a0606", name: "BLACK" },
        { color: "#edf054", name: "YELLOW" },
        { color: "#03ff4e", name: "FLUORESCENT GREEN" },
        { color: "#f707a7", name: "FLUORESCENT PINK" },
        { color: "#ad1515", name: "CHERRY RED" },
        { color: "#ff4747", name: "RED" },
        { color: "#ff0303", name: "FLUORESCENT RED" },
        { color: "#8c7729", name: "GOLD" },
    }
*/
