import React from "react";

function RangePicker({ id, value, onChange, maxWidth = "100%", min = 0, max = 10, step = 1 }) {
    return (
        <div style={{ display: "flex", gap: "0.5rem" }}>
            <input
                type={"range"}
                id={id}
                style={{ maxWidth: maxWidth, width: "100%" }}
                value={value}
                min={min}
                max={max}
                step={step}
                onChange={onChange}
            />
            <span style={{ paddingInline: "0.5rem", backgroundColor: "#e7e7e7" }}>{value}</span>
        </div>
    );
}

export default RangePicker;
