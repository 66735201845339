import React from "react";

import svgColorOne from "../../svg/one.svg";
import svgColorTwo from "../../svg/two.svg";
import svgColorThree from "../../svg/three.svg";

function Amount({ state, setState, toAuthorizeArray }) {
    const allAmounts = [
        { title: "Single Color", svg: svgColorOne },
        { title: "Double Color", svg: svgColorTwo },
        { title: "Triple Color", svg: svgColorThree },
    ];
    const amounts = [];
    allAmounts.forEach((amount, i) => {
        if (toAuthorizeArray.includes(i + 1)) {
            amounts.push(amount);
        }
    });

    return (
        <div className="svgColorAmounts">
            {amounts.map((amount, i) => {
                return (
                    <div
                        onClick={() => {
                            let newState = { ...state };
                            newState["selectedColorSvg"] = i;
                            setState(newState);
                        }}
                        key={i}
                        className={state["selectedColorSvg"] === i ? "amount-btn selected" : "amount-btn"}
                    >
                        <img src={amount.svg} alt={amount.title} />
                        <span>{amount.title}</span>
                    </div>
                );
            })}
        </div>
    );
}

export default Amount;
