import React, { useEffect, useLayoutEffect, useRef } from "react";

function Center({
    selectedColorSvg,
    colorList,
    images,
    text,
    fontFamily,
    textSize,
    textColor,
    textStripeColor,
    imageStripeColor,
    textTrimSize,
    imageTrimSize,
    lefttext,
    leftfontFamily,
    lefttextSize,
    lefttextColor,
    lefttextStripeColor,
    lefttextTrimSize,
    righttext,
    rightfontFamily,
    righttextSize,
    righttextColor,
    righttextStripeColor,
    righttextTrimSize,
    setCanvas,
    canvasScale,
    textx,
    lefttextx,
    righttextx,
}) {
    const canvasElementRef = useRef();
    var FONT_SIZE_MULTIPLIER = 4;

    useLayoutEffect(() => {
        const svgs = [
            {
                id: 1,
                pathCount: 1,
                paths: [
                    {
                        pathName: "path1",
                        d: "M2302.38,45.28c-72.8,0-152.58,69.48-224.47,82.26-97.7,17.41-161.82-31.88-281.38,18.82S1684,298.57,1599.68,329.44V796.16L3200,800c0-201.58-98.38-375.49-255.31-505.35-77.32-64-159.6-39.43-261.41-79.66-51.62-60.42-59.47-92.09-136.51-139.66C2499.6,61.51,2456.3,4.46,2413.48.27,2371.05-3.87,2329.65,45.28,2302.38,45.28Zm-1405.11,0c72.79,0,152.54,69.48,224.37,82.26,97.67,17.41,161.76-31.88,281.27,18.82s112.53,152.21,196.77,183.08V796.15L0,800C0,598.41,98.34,424.51,255.19,294.63c77.28-64,159.54-39.43,261.29-79.66,51.6-60.42,59.44-92.09,136.46-139.66C700.09,61.49,743.38,4.44,786.18.24,828.62-3.87,870,45.28,897.27,45.28Z",
                        transform_x: 0,
                        transform_y: 0,
                        color: 0,
                    },
                ],
                text_center_y: 170,
            },
            {
                id: 2,
                pathCount: 2,
                paths: [
                    {
                        pathName: "path1",
                        d: "M899.51,45.28c73,0,152.92,69.48,224.94,82.26,97.91,17.41,162.16-31.88,282,18.82s112.8,152.21,197.26,183.08V796.15L0,800C0,598.41,98.59,424.51,255.82,294.63c77.48-64,159.94-39.43,262-79.66,51.73-60.42,59.59-92.09,136.8-139.66C701.84,61.49,745.24,4.44,788.14.24,830.69-3.87,872.18,45.28,899.51,45.28Z",
                        transform_x: 0,
                        transform_y: 0,
                        color: 0,
                    },
                    {
                        pathName: "path2",
                        d: "M2300.14,45.28c-73,0-153,69.48-225,82.26-98,17.41-162.23-31.88-282.08,18.82s-112.85,152.21-197.35,183.08V796.16L3200,800c0-201.58-98.62-375.49-255.95-505.35-77.51-64-160-39.43-262.06-79.66-51.75-60.42-59.62-92.09-136.85-139.66-47.29-13.82-90.7-70.87-133.61-75.06C2369-3.87,2327.47,45.28,2300.14,45.28Z",
                        transform_x: 0,
                        transform_y: 0,
                        color: 1,
                    },
                ],
                text_center_y: 170,
            },
            {
                id: 3,
                pathCount: 3,
                paths: [
                    {
                        pathName: "path1",
                        d: "M1066.8,110.19V797.47L0,800C0,598.49,98,424.14,254.4,294.65c77.2-63.8,159.2-39.17,260.8-79.59,51.6-60.65,59.2-92.23,136-139.61C698.4,61.55,741.6,4.7,784,.28s83.6,44.85,110.8,44.85C950.4,45.13,1010,85.56,1066.8,110.19Z",
                        transform_x: 0,
                        transform_y: 0,
                        color: 0,
                    },

                    {
                        pathName: "path2",
                        d: "M2133.2,108.3V797.47L1596,796.21l-529.2,1.26V110.19a248.55,248.55,0,0,0,52.4,17.06C1216.8,144.94,1280.4,95,1400,146.2c119.2,50.54,112.4,152.24,196.4,183.19,84.4-30.95,77.6-132.65,197.2-183.19,120-50.53,184-1.26,282-18.95A239.12,239.12,0,0,0,2133.2,108.3Z",
                        transform_x: 0,
                        transform_y: 0,
                        color: 1,
                    },
                    {
                        pathName: "path3",
                        d: "M3200,800l-1066.8-2.53V108.3c55.6-25.27,113.2-62.54,167.2-62.54,27.2,0,68.8-49.27,111.6-44.85s86.4,61.28,133.6,75.17c77.2,47.38,85.2,79,136.8,139.61,102,40.43,184.4,15.79,262,79.59C3101.2,424.78,3200,598.49,3200,800Z",
                        transform_x: 0,
                        transform_y: 0,
                        color: 2,
                    },
                ],
                text_center_y: 170,
            },
        ];
        const canvas = canvasElementRef.current;
        canvas.width = 3200;
        canvas.height = 800;

        if (fontFamily === "Impact") {
            FONT_SIZE_MULTIPLIER = 8;
        }

        const ctx = canvas.getContext("2d");
        ctx.lineWidth = 1;
        //Draw svgs
        let svg = svgs[selectedColorSvg];
        for (let i = 0; i < svg.paths.length; i++) {
            const path = svg.paths[i];
            ctx.fillStyle = colorList[path.color] ?? "#123123";
            let p = new Path2D(path.d);
            ctx.translate(path.transform_x, path.transform_y);
            ctx.fill(p);
        }

        ctx.fillStyle = textColor ?? "#123123";
        ctx.strokeStyle = textStripeColor ?? "#123123";
        ctx.lineWidth = textTrimSize * FONT_SIZE_MULTIPLIER;
        ctx.font = "normal " + textSize * FONT_SIZE_MULTIPLIER + "px " + fontFamily;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.translate(0, svg.text_center_y);
        ctx.strokeText(text, canvas.width / 2 + textx, canvas.height / 2);
        ctx.fillText(text, canvas.width / 2 + textx, canvas.height / 2);
        const myTextReact = ctx.measureText(text);
        let textBoxLeft = canvas.width / 2 - myTextReact.width / 2 - 10;
        let textBoxRight = canvas.width / 2 + myTextReact.width / 2 + 10;
        if (textBoxLeft > 800) {
            textBoxRight += textBoxLeft - 800;
            textBoxLeft = 800;
        }

        //ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.strokeStyle = imageStripeColor ?? "#123123";
        ctx.lineWidth = imageTrimSize * 4;
        ctx.lineJoin = "bevel";
        //left side image
        if (images[0]) {
            let [imageManagedWidth, imageManagedHeight] = manageAspectRatio(images[0].width, images[0].height);
            ctx.drawImage(
                images[0],
                textBoxLeft - imageManagedWidth,
                canvas.height / 2 - imageManagedHeight / 2,
                imageManagedWidth,
                imageManagedHeight
            );
            if (imageTrimSize > 0) {
                ctx.strokeRect(
                    textBoxLeft - imageManagedWidth - imageTrimSize * 2,
                    canvas.height / 2 - imageManagedHeight / 2 - imageTrimSize * 2,
                    imageManagedWidth + imageTrimSize * 4,
                    imageManagedHeight + imageTrimSize * 4
                );
            }
            textBoxLeft = textBoxLeft - imageManagedWidth - 10;
        }
        if (images[2]) {
            let [imageManagedWidth, imageManagedHeight] = manageAspectRatio(images[2].width, images[2].height);
            ctx.drawImage(
                images[2],
                textBoxLeft - imageManagedWidth,
                canvas.height / 2 - imageManagedHeight / 2,
                imageManagedWidth,
                imageManagedHeight
            );
            if (imageTrimSize > 0) {
                ctx.strokeRect(
                    textBoxLeft - imageManagedWidth - imageTrimSize * 2,
                    canvas.height / 2 - imageManagedHeight / 2 - imageTrimSize * 2,
                    imageManagedWidth + imageTrimSize * 4,
                    imageManagedHeight + imageTrimSize * 4
                );
            }
            textBoxLeft = textBoxLeft - imageManagedWidth - 10;
        }
        if (images[1]) {
            let [imageManagedWidth, imageManagedHeight] = manageAspectRatio(images[1].width, images[1].height);
            ctx.drawImage(
                images[1],
                textBoxRight,
                canvas.height / 2 - imageManagedHeight / 2,
                imageManagedWidth,
                imageManagedHeight
            );
            if (imageTrimSize > 0) {
                ctx.strokeRect(
                    textBoxRight - imageTrimSize * 2,
                    canvas.height / 2 - imageManagedHeight / 2 - imageTrimSize * 2,
                    imageManagedWidth + imageTrimSize * 4,
                    imageManagedHeight + imageTrimSize * 4
                );
            }
            textBoxRight += imageManagedWidth + 10;
        }
        if (images[3]) {
            let [imageManagedWidth, imageManagedHeight] = manageAspectRatio(images[3].width, images[3].height);
            ctx.drawImage(
                images[3],
                textBoxRight,
                canvas.height / 2 - imageManagedHeight / 2,
                imageManagedWidth,
                imageManagedHeight
            );
            if (imageTrimSize > 0) {
                ctx.strokeRect(
                    textBoxRight - imageTrimSize * 2,
                    canvas.height / 2 - imageManagedHeight / 2 - imageTrimSize * 2,
                    imageManagedWidth + imageTrimSize * 4,
                    imageManagedHeight + imageTrimSize * 4
                );
            }
            textBoxRight += imageManagedWidth + 10;
        }

        //Left side text
        if (lefttextx) {
            textBoxLeft += lefttextx;
        }
        if (lefttext) {
            ctx.fillStyle = lefttextColor ?? "#123123";
            ctx.strokeStyle = lefttextStripeColor ?? "#123123";
            ctx.lineWidth = lefttextTrimSize * FONT_SIZE_MULTIPLIER;
            ctx.font = "normal " + lefttextSize * FONT_SIZE_MULTIPLIER + "px " + leftfontFamily;
            ctx.textAlign = "right";
            ctx.textBaseline = "middle";
            if (lefttextTrimSize * FONT_SIZE_MULTIPLIER > 0) {
                ctx.strokeText(lefttext, textBoxLeft, canvas.height / 2);
            }
            ctx.fillText(lefttext, textBoxLeft, canvas.height / 2);
        }
        //Right side text
        if (righttextx) {
            textBoxRight += righttextx;
        }
        if (righttext) {
            ctx.fillStyle = righttextColor ?? "#123123";
            ctx.strokeStyle = righttextStripeColor ?? "#123123";
            ctx.lineWidth = righttextTrimSize * FONT_SIZE_MULTIPLIER;
            ctx.font = "normal " + righttextSize * FONT_SIZE_MULTIPLIER + "px " + rightfontFamily;
            ctx.textAlign = "left";
            ctx.textBaseline = "middle";
            if (righttextTrimSize * FONT_SIZE_MULTIPLIER > 0) {
                ctx.strokeText(righttext, textBoxRight, canvas.height / 2);
            }
            ctx.fillText(righttext, textBoxRight, canvas.height / 2);
        }
    }, [
        selectedColorSvg,
        colorList,
        text,
        textSize,
        fontFamily,
        textColor,
        images,
        textStripeColor,
        imageStripeColor,
        textTrimSize,
        imageTrimSize,
        lefttext,
        leftfontFamily,
        lefttextSize,
        lefttextColor,
        lefttextStripeColor,
        lefttextTrimSize,
        righttext,
        rightfontFamily,
        righttextSize,
        righttextColor,
        righttextStripeColor,
        righttextTrimSize,
        textx,
        lefttextx,
        righttextx,
    ]);

    useEffect(() => {
        setCanvas(canvasElementRef.current);
        canvasElementRef.current.style.transform = `scale(${canvasScale})`;
    }, [canvasScale]);

    function manageAspectRatio(width, height, baseValue = 320) {
        var maxWidth = baseValue; // Max width for the image
        var maxHeight = baseValue; // Max height for the image
        var ratio = 0; // Used for aspect ratio

        // Check if the current width is larger than the max
        if (width > maxWidth) {
            ratio = maxWidth / width; // get ratio for scaling image
            height = height * ratio; // Reset height to match scaled image
            width = width * ratio; // Reset width to match scaled image
        }

        // Check if current height is larger than max
        if (height > maxHeight) {
            ratio = maxHeight / height; // get ratio for scaling image
            width = width * ratio; // Reset width to match scaled image
            height = height * ratio; // Reset height to match scaled image
        }
        return [width, height];
    }

    return (
        <div className="center">
            <canvas ref={canvasElementRef} id="canvas"></canvas>
        </div>
    );
}

export default Center;
