import { faShapes } from "@fortawesome/free-solid-svg-icons";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";
import Amount from "./Stripe/Amount";
import Colors from "./Stripe/Colors";
import Images from "./Stripe/Images";
import StripeText from "./Stripe/Text";

function Right({ state, setState, exportButtonClickHandler, toAuthorizeArray }) {
    const tabs = [
        {
            name: "Shapes",
            title: "Stripes amount",
            type: "fontawsome",
            component: <Amount state={state} setState={setState} toAuthorizeArray={toAuthorizeArray} />,
            icon: faShapes,
        },
        {
            name: "Stripe Color",
            title: "Stripes options",
            type: "fontawsome",
            component: <Colors state={state} setState={setState} />,
            icon: faPalette,
        },
        {
            name: "Text",
            title: "Nickname",
            component: <StripeText state={state} setState={setState} toAuthorizeArray={toAuthorizeArray} />,
            type: "svg",
            icon: (
                <>
                    <svg
                        style={{ fill: "currentColor" }}
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 25.531 25.531"
                    >
                        <g>
                            <g>
                                <path
                                    fill="currentColor"
                                    d="M25.198,6.273c-0.014,0.23-0.045,0.389-0.087,0.467c-0.045,0.084-0.176,0.145-0.392,0.183
                                    c-0.469,0.104-0.781-0.074-0.935-0.533C23.239,4.7,22.59,3.578,21.84,3.016c-1.041-0.773-2.862-1.161-5.469-1.161
                                    c-1.054,0-1.633,0.115-1.734,0.343c-0.036,0.075-0.057,0.184-0.057,0.324v18.999c0,0.812,0.188,1.383,0.571,1.709
                                    c0.382,0.32,1.069,0.731,2.201,0.999c0.483,0.103,0.97,0.2,1.034,0.239c0.46,0,0.504,1.057-0.376,1.057
                                    c-0.025,0.016-10.375-0.008-10.375-0.008s-0.723-0.439-0.074-1.023c0.271-0.121,0.767-0.343,0.767-0.343s1.83-0.614,2.211-1.009
                                    c0.434-0.445,0.648-1.164,0.648-2.154V2.521c0-0.369-0.229-0.585-0.687-0.647c-0.049-0.015-0.425-0.02-1.122-0.02
                                    c-2.415,0-4.191,0.418-5.338,1.259C3.176,3.735,2.411,4.877,1.737,6.545C1.52,7.065,1.22,7.234,0.84,7.058
                                    C0.408,6.957,0.251,6.719,0.363,6.353c0.445-1.374,0.668-3.31,0.668-5.814c0-0.292,0.387-0.586,1.163-0.533L23.56,0.064
                                    c0.709-0.104,1.096,0.012,1.16,0.343C25.076,2.096,25.234,4.052,25.198,6.273z"
                                />
                            </g>
                        </g>
                    </svg>
                </>
            ),
        },
        {
            name: "Images",
            title: "Images",
            type: "fontawsome",
            component: <Images state={state} setState={setState} toAuthorizeArray={toAuthorizeArray} />,
            icon: faFileImage,
        },
    ];

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div className="right-container">
            <div className="right">
                <div className="tabs">
                    {tabs.map((tab, i) => (
                        <button
                            onClick={() => setSelectedTab(i)}
                            key={i}
                            className={selectedTab === i ? "tab selected" : "tab"}
                        >
                            <div className={tab.type === "fontawsome" ? "icon" : "svg-icon"}>
                                {tab.type === "fontawsome" ? <FontAwesomeIcon icon={tab.icon} /> : tab.icon}
                            </div>
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    <div className="tab-header">
                        <FontAwesomeIcon icon={faArrowRight} />
                        {tabs[selectedTab].title}
                    </div>
                    <div className="tab-body">{tabs[selectedTab].component}</div>
                </div>
            </div>
            <button className="export-btn" onClick={exportButtonClickHandler}>
                Export
            </button>
        </div>
    );
}

export default Right;
