import React from "react";
import ColorPicker from "../Picker/ColorPicker";

function Colors({ state, setState }) {
    var colors = [...state.colorList];

    const makeCountingDigit = (num) => {
        if (num === 1) {
            return "1st";
        } else if (num === 2) {
            return "2nd";
        } else if (num === 3) {
            return "3rd";
        } else {
            return num + "th";
        }
    };

    return (
        <div className="color-list">
            {new Array(state.selectedColorSvg + 1).fill(0).map((_, i) => {
                return (
                    <div key={i} className="color">
                        <h4>{makeCountingDigit(i + 1)} Stripe</h4>
                        <div className="color-input">
                            <span>Color: </span>
                            <ColorPicker
                                id={"color" + i}
                                onColorChange={(e) => {
                                    colors[i] = e.target.value;
                                    let newState = { ...state };
                                    newState["colorList"] = colors;
                                    setState(newState);
                                }}
                                value={colors[i]}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default Colors;
