import React from "react";
import ColorPicker from "../Picker/ColorPicker";
import RangePicker from "../Picker/RangePicker";

function Images({ state, setState, toAuthorizeArray }) {
    const refImages = [...state["images"]];

    return (
        <div className="choose-images">
            {toAuthorizeArray.includes(6) && (
                <>
                    <div className="single-image">
                        <h4>Left Side Images</h4>
                        <div>
                            <input
                                type="file"
                                onChange={(e) => {
                                    const [file] = e.target.files;

                                    if (file) {
                                        let img = new Image();
                                        img.onload = function () {
                                            refImages[0] = img;
                                            let newState = { ...state };
                                            newState["images"] = refImages;
                                            setState(newState);
                                        };
                                        img.src = URL.createObjectURL(file);
                                    }
                                }}
                                accept="image/*"
                                id="left-side-image"
                            />
                            <label htmlFor="left-side-image">Select Image 1</label>
                        </div>
                        <div>
                            <input
                                type="file"
                                onChange={(e) => {
                                    const [file] = e.target.files;

                                    if (file) {
                                        let img = new Image();
                                        img.onload = function () {
                                            refImages[2] = img;
                                            let newState = { ...state };
                                            newState["images"] = refImages;
                                            setState(newState);
                                        };
                                        img.src = URL.createObjectURL(file);
                                    }
                                }}
                                accept="image/*"
                                id="left-side-image-2"
                            />
                            <label htmlFor="left-side-image-2">Select Image 2</label>
                        </div>
                    </div>
                    <div className="single-image">
                        <h4>Right Side Images</h4>
                        <div>
                            <input
                                onChange={(e) => {
                                    const [file] = e.target.files;
                                    if (file) {
                                        let img = new Image();
                                        img.onload = function () {
                                            refImages[1] = img;
                                            let newState = { ...state };
                                            newState["images"] = refImages;
                                            setState(newState);
                                        };
                                        img.src = URL.createObjectURL(file);
                                    }
                                }}
                                type="file"
                                accept="image/*"
                                id="right-side-image"
                            />
                            <label htmlFor="right-side-image">Select Image 1</label>
                        </div>
                        <div>
                            <input
                                onChange={(e) => {
                                    const [file] = e.target.files;
                                    if (file) {
                                        let img = new Image();
                                        img.onload = function () {
                                            refImages[3] = img;
                                            let newState = { ...state };
                                            newState["images"] = refImages;
                                            setState(newState);
                                        };
                                        img.src = URL.createObjectURL(file);
                                    }
                                }}
                                type="file"
                                accept="image/*"
                                id="right-side-image-2"
                            />
                            <label htmlFor="right-side-image-2">Select Image 2</label>
                        </div>
                    </div>

                    {toAuthorizeArray.includes(7) && (
                        <>
                            <div className="text-input">
                                <h4 htmlFor="image-stripe-color">Trim Color</h4>
                                <ColorPicker
                                    id={"image-stripe-color"}
                                    value={state["imageStripeColor"]}
                                    maxWidth="100%"
                                    onColorChange={(e) => {
                                        let newState = { ...state };
                                        newState["imageStripeColor"] = e.target.value;
                                        setState(newState);
                                    }}
                                />
                            </div>
                            <div className="text-input">
                                <h4 htmlFor="image-stripe-size">Trim Size</h4>
                                <RangePicker
                                    id={"image-stripe-size"}
                                    value={state["imageTrimSize"]}
                                    maxWidth="100%"
                                    onChange={(e) => {
                                        let newState = { ...state };
                                        newState["imageTrimSize"] = e.target.value;
                                        setState(newState);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default Images;
