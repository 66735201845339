import React from "react";
import ColorPicker from "../Picker/ColorPicker";
import RangePicker from "../Picker/RangePicker";
import TextPositionPicker from "../Picker/TextPositionPicker";

function TextComponent({
    text,
    fontFamily,
    textSize,
    textColor,
    textStripeColor,
    textTrimSize,
    state,
    setState,
    toAuthorizeArray,
    x,
    y,
}) {
    const fontSizes = [
        12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50, 52, 54, 56, 58, 60, 62, 64, 66,
        68, 70, 72, 74, 76, 78, 80,
    ];
    const fontFamilies = [
        { name: "College", value: "College" },
        { name: "Impact", value: "Impact" },
        { name: "Blood Dripping", value: "Pieces" },
        { name: "Hand Script", value: "Sweet Sensation" },
    ];

    return (
        <>
            <div className="text-input">
                <h4 htmlFor="text">Text</h4>
                <input
                    type="text"
                    id="text"
                    value={state[text]}
                    onChange={(e) => {
                        let newState = { ...state };
                        newState[text] = e.target.value;
                        setState(newState);
                    }}
                />
            </div>

            <div className="text-family">
                {fontFamilies.map((ff, i) => {
                    return (
                        <div
                            onClick={() => {
                                let newState = { ...state };
                                newState[fontFamily] = ff.value;
                                setState(newState);
                            }}
                            key={i}
                            className={state[fontFamily] === ff.value ? "font-family-btn selected" : "font-family-btn"}
                        >
                            {ff.name}
                        </div>
                    );
                })}
            </div>

            <div className="text-input">
                <h4 htmlFor="size">Size</h4>
                <select
                    value={state[textSize]}
                    id="size"
                    onChange={(e) => {
                        let newState = { ...state };
                        newState[textSize] = e.target.value;
                        setState(newState);
                    }}
                >
                    {fontSizes.map((size, i) => {
                        return (
                            <option key={i} value={size}>
                                {size + "px"}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className="text-input">
                <h4 htmlFor="text-color">Color</h4>
                <ColorPicker
                    id={"text-color"}
                    maxWidth="100%"
                    value={state[textColor]}
                    onColorChange={(e) => {
                        let newState = { ...state };
                        newState[textColor] = e.target.value;
                        setState(newState);
                    }}
                />
            </div>
            {toAuthorizeArray.includes(5) && (
                <>
                    <div className="text-input">
                        <h4 htmlFor="text-stripe-color">Trim Color</h4>
                        <ColorPicker
                            id={"text-stripe-color"}
                            value={state[textStripeColor]}
                            maxWidth="100%"
                            onColorChange={(e) => {
                                let newState = { ...state };
                                newState[textStripeColor] = e.target.value;
                                setState(newState);
                            }}
                        />
                    </div>
                    <div className="text-input">
                        <h4 htmlFor="text-stripe-size">Trim Size</h4>
                        <RangePicker
                            id={"text-stripe-size"}
                            value={state[textTrimSize]}
                            maxWidth="100%"
                            onChange={(e) => {
                                let newState = { ...state };
                                newState[textTrimSize] = parseInt(e.target.value);
                                setState(newState);
                            }}
                        />
                    </div>
                </>
            )}
            <div className="text-input">
                <h4 htmlFor="text-position">Change Text Position</h4>
                <RangePicker
                    id={"text-position-" + x}
                    value={state[x]}
                    maxWidth="100%"
                    onChange={(e) => {
                        let newState = { ...state };
                        newState[x] = parseInt(e.target.value);
                        setState(newState);
                    }}
                    min={-200}
                    max={200}
                    step={5}
                />
            </div>

            {/* <div className="text-input">
                <h4 htmlFor="text-position">Text Position</h4>
                <TextPositionPicker
                    id={text + "-position-input"}
                    onChangeX={(e) => {
                        let newState = { ...state };
                        newState[x] = e.target.value;
                        setState(newState);
                    }}
                    onChangeY={(e) => {
                        let newState = { ...state };
                        newState[y] = e.target.value;
                        setState(newState);
                    }}
                    x={state[x]}
                    y={state[y]}
                />
            </div> */}
        </>
    );
}

export default TextComponent;
