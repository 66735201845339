import React, { useState } from "react";
import TextComponent from "./TextComponent";

function Text({ state, setState, toAuthorizeArray }) {
    const [textPosition, setTextPosition] = useState(1);

    const textTabs = [
        <TextComponent
            text={"lefttext"}
            fontFamily={"leftfontFamily"}
            textSize={"lefttextSize"}
            textColor={"lefttextColor"}
            textStripeColor={"lefttextStripeColor"}
            textTrimSize={"lefttextTrimSize"}
            x={"lefttextx"}
            y={"lefttexty"}
            state={state}
            setState={setState}
            toAuthorizeArray={toAuthorizeArray}
        />,
        <TextComponent
            text={"text"}
            fontFamily={"fontFamily"}
            textSize={"textSize"}
            textColor={"textColor"}
            textStripeColor={"textStripeColor"}
            textTrimSize={"textTrimSize"}
            state={state}
            setState={setState}
            toAuthorizeArray={toAuthorizeArray}
            x={"textx"}
            y={"texty"}
        />,
        <TextComponent
            text={"righttext"}
            fontFamily={"rightfontFamily"}
            textSize={"righttextSize"}
            textColor={"righttextColor"}
            textStripeColor={"righttextStripeColor"}
            textTrimSize={"righttextTrimSize"}
            state={state}
            setState={setState}
            toAuthorizeArray={toAuthorizeArray}
            x={"righttextx"}
            y={"righttexty"}
        />,
    ];

    return (
        <div className="text" style={{ marginBottom: "1rem" }}>
            <div className="text-input">
                <h4 htmlFor="text-position">Text Position</h4>
                <select
                    value={textPosition}
                    id="text-position"
                    onChange={(e) => setTextPosition(parseInt(e.target.value))}
                >
                    <option value="0">Left</option>
                    <option value="1">Middle</option>
                    <option value="2">Right</option>
                </select>
            </div>
            {toAuthorizeArray.includes(4) && <>{textTabs[textPosition]}</>}
        </div>
    );
}

export default Text;
