import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus, faRotate } from "@fortawesome/free-solid-svg-icons";

function Left({ canvasScale, setCanvasScale, setState }) {
    return (
        <div className="left">
            <div onClick={() => setCanvasScale(canvasScale + 0.1)}>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                Zoom In
            </div>
            <div onClick={() => setCanvasScale(canvasScale - 0.1)}>
                <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                Zoom Out
            </div>
            <div
                onClick={() => {
                    setCanvasScale(1);
                    setState({
                        selectedColorSvg: 0,
                        colorList: ["black", "black", "black"],
                        images: new Array(4).fill(null),
                        text: "Hello World",
                        fontFamily: "Macondo Swash Caps",
                        textSize: 74,
                        textColor: "#DE3163",
                        textStripeColor: "darkblue",
                        textTrimSize: 0,
                        lefttext: "",
                        leftfontFamily: "Macondo Swash Caps",
                        lefttextSize: 60,
                        lefttextColor: "#DE3163",
                        lefttextStripeColor: "darkblue",
                        lefttextTrimSize: 0,
                        righttext: "",
                        rightfontFamily: "Macondo Swash Caps",
                        righttextSize: 60,
                        righttextColor: "#DE3163",
                        righttextStripeColor: "darkblue",
                        righttextTrimSize: 0,
                        imageStripeColor: "darkblue",
                        imageTrimSize: 0,
                        textx: 0,
                        texty: 0,
                        lefttextx: 0,
                        lefttexty: 0,
                        righttextx: 0,
                        righttexty: 0,
                    });
                }}
            >
                <FontAwesomeIcon icon={faRotate} />
                Reset
            </div>
        </div>
    );
}

export default Left;
